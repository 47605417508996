// keywordDictionary.js
export const keywordDictionary = {
    'Anime': {
        keywords: 'anime, manga, japanese, cartoon, shonen, shojo, otaku, fantasy',
        subjects: [3, 7, 36] // Anime & cartoon, Comics & manga, TV
    },
    'Animal Art': {
        keywords: 'animal, wildlife, pets, fauna, nature, mammals, birds, illustrations',
        subjects: [2, 26, 16] // Animal, Pet portrait, Landscape & scenery
    },
    'Religious Art': {
        keywords: 'religious, spiritual, icons, sacred, worship, faith, divine, holy, Buddha, Jesus',
        subjects: [29, 15, 32] // Religious, Inspirational saying, Stars & celestial
    },
    'Graffiti-Banksy Art': {
        keywords: 'street, urban, graffiti, banksy, stencil, spraypaint, mural, edgy',
        subjects: [4, 1, 27] // Architecture & cityscape, Abstract & geometric, Phrase & saying
    },
    'Erotic': {
        keywords: 'erotic, sensual, nude, intimate, boudoir, passion, desire, romance',
        subjects: [23, 18, 10] // Nudes, Love & friendship, Flowers
    },
    'Ethnic': {
        keywords: 'ethnic, cultural, folk, traditional, heritage, indigenous, tribal, authentic',
        subjects: [12, 24, 35] // Geography & locale, Patriotic & flags, Travel & transportation
    },
    'Landscape Art': {
        keywords: 'landscape, nature, scenery, outdoor, panorama, horizon, countryside, vista',
        subjects: [16, 28, 5] // Landscape & scenery, Plants & trees, Beach & tropical
    },
    'Ship Art': {
        keywords: 'ship, marine, vessel, ocean, sailing, nautical, maritime, seascape',
        subjects: [22, 35, 4] // Nautical, Travel & transportation, Architecture & cityscape
    },
    'Abstract': {
        keywords: 'abstract, shapes, geometric, color, form, modernist, minimal, conceptual',
        subjects: [1, 8, 33] // Abstract & geometric, Fantasy & Sci Fi, Steampunk
    },
    'Modern Art': {
        keywords: 'modern, contemporary, trendy, innovative, current, avantgarde, sleek, cuttingedge',
        subjects: [1, 30, 4] // Abstract & geometric, Science & tech, Architecture & cityscape
    },
    'Cowboy Art': {
        keywords: 'cowboy, western, ranch, frontier, desert, rodeo, horses, pioneer',
        subjects: [38, 16, 35] // Western & cowboy, Landscape & scenery, Travel & transportation
    },
    'Woman Art': {
        keywords: 'woman, female, lady, feminine, portrait, muse, elegant, goddess',
        subjects: [25, 9, 15] // People & portrait, Fashion, Inspirational saying
    },
    'Mark Rothko': {
        keywords: 'rothko, colorfield, abstract, minimal, painting, modernism, hues, meditative',
        subjects: [1, 16, 32] // Abstract & geometric, Landscape & scenery, Stars & celestial
    },
    'Japon Art': {
        keywords: 'japan, japanese, anime, manga, oriental, ukiyoe, traditional, cherryblossom',
        subjects: [3, 7, 12] // Anime & cartoon, Comics & manga, Geography & locale
    },
    'Fashion': {
        keywords: 'fashion, style, runway, designer, chic, couture, trend, model',
        subjects: [9, 25, 27] // Fashion, People & portrait, Phrase & saying
    },
    'Famous Art': {
        keywords: 'famous, masterpiece, classic, renowned, museum, iconic, legendary, heritage',
        subjects: [25, 4, 16] // People & portrait, Architecture & cityscape, Landscape & scenery
    },
    'Dance': {
        keywords: 'dance, movement, ballet, hiphop, performance, stage, music, rhythm',
        subjects: [21, 25, 6] // Music, People & portrait, Bollywood
    },
    'Surreal Art': {
        keywords: 'surreal, dreamlike, unreal, imaginative, bizarre, fantasy, abstract, psychedelic',
        subjects: [1, 8, 33] // Abstract & geometric, Fantasy & Sci Fi, Steampunk
    },
    'Flowers': {
        keywords: 'flowers, bloom, floral, garden, botanical, colorful, bouquet, petals',
        subjects: [10, 28, 16] // Flowers, Plants & trees, Landscape & scenery
    },
    'Love Art': {
        keywords: 'love, romance, heart, passion, affection, emotional, relationship, couple',
        subjects: [18, 25, 10] // Love & friendship, People & portrait, Flowers
    },
    'Best Seller': {
        keywords: 'bestseller, popular, trending, toprated, highdemand, favorite, iconic, classic',
        subjects: [1, 16, 2] // Abstract & geometric, Landscape & scenery, Animal
    },
    'Graffiti- Banksy Art': {
        keywords: 'street, urban, graffiti, banksy, stencil, spraypaint, mural, edgy',
        subjects: [4, 1, 27] // Architecture & cityscape, Abstract & geometric, Phrase & saying
    },
    'Graffiti': {
        keywords: 'street, urban, graffiti, banksy, stencil, spraypaint, mural, edgy',
        subjects: [4, 1, 27] // Architecture & cityscape, Abstract & geometric, Phrase & saying
    },
    'Animals Art': {
        keywords: 'animal, wildlife, pets, fauna, nature, mammals, birds, illustrations',
        subjects: [2, 26, 16] // Animal, Pet portrait, Landscape & scenery
    },
    'Religion': {
        keywords: 'religious, spiritual, icons, sacred, worship, faith, divine, holy, Buddha, Jesus',
        subjects: [29, 15, 32] // Religious, Inspirational saying, Stars & celestial
    },
    'Motivation': {
        keywords: 'motivation, inspirational, ambition, success, mindset, achieve, goal, determination',
        subjects: [15, 27, 32] // Inspirational saying, Phrase & saying, Stars & celestial
    }
};

export const findMatchingKeywords = (sectionTitle) => {
    const lowercaseTitle = sectionTitle.toLowerCase();
    
    // Tam eşleşme kontrolü
    const exactMatch = Object.entries(keywordDictionary).find(([key]) => 
        key.toLowerCase() === lowercaseTitle
    );
    
    if (exactMatch) return exactMatch[1];
    
    // Kısmi eşleşme kontrolü
    const partialMatch = Object.entries(keywordDictionary).find(([key]) => 
        lowercaseTitle.includes(key.toLowerCase()) || 
        key.toLowerCase().includes(lowercaseTitle)
    );
    
    return partialMatch ? partialMatch[1] : null;
};