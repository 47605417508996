import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { toast } from 'react-toastify';

const PSDMockup = ({ profileId }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPSD, setCurrentPSD] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Mevcut PSD'yi yükle
  React.useEffect(() => {
    const fetchCurrentPSD = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://api.canvashelper.com/api/psd-mockup/${profileId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data) {
          setCurrentPSD(response.data);
        }
      } catch (error) {
        console.error('Error fetching PSD:', error);
      }
    };

    fetchCurrentPSD();
  }, [profileId]);

  const onDrop = useCallback(async (acceptedFiles) => {
    // Eğer zaten bir PSD varsa yüklemeyi engelle
    if (currentPSD) {
      toast.error('Please delete the existing PSD file first');
      return;
    }

    const file = acceptedFiles[0];
    
    if (!file.name.toLowerCase().endsWith('.psd')) {
      toast.error('Please upload only PSD files');
      return;
    }

    setUploadedFile(file);
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('profileId', profileId);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://api.canvashelper.com/api/upload-psd', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.success) {
        toast.success('PSD file uploaded successfully');
        setCurrentPSD(response.data.psd);
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.message || 'Error uploading PSD file');
    } finally {
      setIsUploading(false);
    }
  }, [profileId, currentPSD]);

  const handleDelete = async () => {
    if (!currentPSD) return;

    if (!window.confirm('Are you sure you want to delete this PSD mockup?')) {
      return;
    }

    setIsDeleting(true);
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://api.canvashelper.com/api/psd-mockup/${profileId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setCurrentPSD(null);
      toast.success('PSD mockup deleted successfully');
    } catch (error) {
      console.error('Delete error:', error);
      toast.error(error.response?.data?.message || 'Error deleting PSD file');
    } finally {
      setIsDeleting(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/psd': ['.psd']
    },
    multiple: false,
    disabled: !!currentPSD || isUploading // Eğer mevcut PSD varsa veya yükleme yapılıyorsa dropzone'u devre dışı bırak
  });

  return (
    <div className="p-4">
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2">PSD Mockup Template</h3>
        <p className="text-sm text-gray-600 mb-4">
          Upload a PSD file with a "yourImageHere" layer in the root directory. 
          This will be used as a template for automatic mockup generation.
        </p>
      </div>

      {/* Current PSD Display */}
      {currentPSD && (
        <div className="mb-6 p-4 bg-gray-50 rounded-lg">
            <div className="flex justify-between items-start">
            <div>
                <p className="text-sm font-medium">Current Template:</p>
                <p className="text-sm text-gray-600">{currentPSD.filename}</p>
                <p className="text-xs text-gray-500">
                Uploaded: {new Date(currentPSD.created_at).toLocaleDateString()}
                </p>
            </div>
            <button
                onClick={handleDelete}
                disabled={isDeleting}
                className={`px-3 py-1 text-sm text-red-600 hover:text-red-800 
                rounded-md hover:bg-red-50 transition-colors
                ${isDeleting ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
            >
                {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
            </div>
            {currentPSD.preview_url && (
            <div className="mt-4">
                <p className="text-sm font-medium mb-2">Preview:</p>
                <div className="w-full max-w-md mx-auto border rounded-lg overflow-hidden">
                <img 
                    src={currentPSD.preview_url} 
                    alt="PSD Preview"
                    className="w-full h-auto"
                />
                </div>
            </div>
            )}
        </div>
        )}

      {/* Dropzone - Sadece mevcut PSD yoksa göster */}
      {!currentPSD && (
        <div
          {...getRootProps()}
          className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
            ${isDragActive ? 'border-purple-500 bg-purple-50' : 'border-gray-300 hover:border-purple-400'}
            ${isUploading ? 'opacity-50 pointer-events-none' : ''}`}
        >
          <input {...getInputProps()} />
          
          <div className="space-y-4">
            <div className="text-4xl">📁</div>
            {isDragActive ? (
              <p className="text-purple-500">Drop the PSD file here...</p>
            ) : (
              <>
                <p className="text-gray-600">Drag and drop a PSD file here, or click to select</p>
                <p className="text-sm text-gray-500">Only .psd files are accepted</p>
              </>
            )}
            
            {isUploading && (
              <div className="mt-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500 mx-auto"></div>
                <p className="text-sm text-gray-500 mt-2">Uploading...</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Requirements Info - Her zaman görünür */}
      <div className="mt-6 bg-blue-50 p-4 rounded-lg">
        <h4 className="font-medium text-blue-800 mb-2">Requirements:</h4>
        <ul className="text-sm text-blue-700 space-y-1">
          <li>• Only one PSD file can be uploaded per profile</li>
          <li>• PSD file must contain a layer named "yourImageHere" in the root directory</li>
          <li>• The "yourImageHere" layer will be used for automatic image placement</li>
          <li>• Keep your PSD file size under 50MB for optimal performance</li>
          <li>• Make sure your PSD is in RGB color mode</li>
        </ul>
      </div>
    </div>
  );
};

export default PSDMockup;