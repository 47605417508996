import React from 'react';
import { Truck, ExternalLink, Gift } from 'lucide-react';
import { formatPrice, formatDate } from './helperFunctions';

const PackageBox = ({ pkg, index, isGift }) => {
  return (
    <div className="relative">
      {isGift && (
        <>
          <div className="absolute -top-1 -right-1 w-20 h-20 overflow-hidden">
            <div className="absolute transform rotate-45 bg-gradient-to-r from-pink-500 to-rose-500 text-white text-xs font-semibold py-1 right-[-35px] top-[32px] w-[170px] text-center">
              Gift
            </div>
          </div>
        </>
      )}
      
      <div className={`bg-gray-50 rounded-lg p-4 ${isGift ? 'border-2 border-pink-200' : ''}`}>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div>
            <p className="text-xs text-gray-500">Package ID</p>
            <p className="text-sm font-medium">{pkg.id || `Package ${index + 1}`}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Length</p>
            <p className="text-sm font-medium">{pkg.length || 'N/A'} {pkg.dimension_unit || 'in'}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Width</p>
            <p className="text-sm font-medium">{pkg.width || 'N/A'} {pkg.dimension_unit || 'in'}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Height</p>
            <p className="text-sm font-medium">{pkg.height || 'N/A'} {pkg.dimension_unit || 'in'}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Weight</p>
            <p className="text-sm font-medium">{pkg.weight || 'N/A'} {pkg.weight_unit || 'oz'}</p>
          </div>
          {pkg.tracking_code && (
            <div className="col-span-2">
              <p className="text-xs text-gray-500">Tracking</p>
              <p className="text-sm font-medium text-blue-600">{pkg.tracking_code}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmptyPackageBox = ({ isGift }) => {
  return (
    <div className="relative">
      {isGift && (
        <>
          <div className="absolute -top-1 -right-1 w-16 h-16 overflow-hidden">
            <div className="absolute transform rotate-45 bg-gradient-to-r from-pink-500 to-rose-500 text-white text-xs font-semibold py-1 right-[-28px] top-[26px] w-[140px] text-center">
              Gift
            </div>
          </div>
        </>
      )}
      <div className={`text-sm text-gray-500 text-center p-4 bg-gray-50 rounded-lg ${isGift ? 'border-2 border-pink-200' : ''}`}>
        No package information available
      </div>
    </div>
  );
};

const GiftMessage = ({ message }) => {
  if (!message) return null;
  
  return (
    <div className="mt-4 p-4 bg-pink-50 rounded-lg border border-pink-200">
      <div className="flex items-center gap-2 mb-2">
        <Gift className="w-4 h-4 text-pink-500" />
        <span className="font-medium text-pink-700">Gift Message</span>
      </div>
      <p className="text-gray-600 text-sm italic">"{message}"</p>
    </div>
  );
};

const ShippingInformation = ({ orderData }) => {
  const getCarrierLogo = (carrierName) => {
    if (!carrierName) return null;
    const carrier = carrierName.toLowerCase();
    
    if (carrier.includes('ups')) {
      return "https://upload.wikimedia.org/wikipedia/commons/1/1b/UPS_Logo_Shield_2017.svg";
    } else if (carrier.includes('fedex')) {
      return "https://upload.wikimedia.org/wikipedia/commons/9/9d/FedEx_Express.svg";
    } else if (carrier.includes('usps')) {
      return "https://upload.wikimedia.org/wikipedia/fi/3/37/United_States_Postal_Service_Logo.svg";
    }
    return null;
  };

  const getTrackingUrl = (carrier, trackingCode) => {
    if (!carrier || !trackingCode) return '#';
    const carrierName = carrier.toLowerCase();
    
    if (carrierName.includes('ups')) {
      return `https://www.ups.com/track?tracknum=${trackingCode}`;
    } else if (carrierName.includes('fedex')) {
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingCode}`;
    } else if (carrierName.includes('usps')) {
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingCode}`;
    }
    return '#';
  };

  const isGiftOrder = Boolean(orderData.is_gift);

  return (
    <div className="bg-white rounded-xl border border-gray-200">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
            <Truck className="w-5 h-5 text-gray-500" />
            Shipping Information
          </h2>
          {isGiftOrder && (
            <div className="flex items-center gap-2 text-pink-600">
              <Gift className="w-5 h-5" />
              <span className="text-sm font-medium">Gift Order</span>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <div>
            <p className="text-sm text-gray-500">Processing Time</p>
            <p className="font-medium mt-1">
              {orderData.items[0]?.processing_days?.min}-{orderData.items[0]?.processing_days?.max} days
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Shipping Cost</p>
            <p className="font-medium mt-1">{formatPrice(orderData.shipping.cost)}</p>
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-sm font-medium text-gray-700">Shipments</h3>
          {orderData.shipments?.map((shipment, index) => {
            const logoUrl = getCarrierLogo(shipment.carrier_name);
            return (
              <div key={index} className="flex items-center gap-4 p-3 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-3 w-48">
                  {logoUrl ? (
                    <>
                      <img 
                        src={logoUrl} 
                        alt={shipment.carrier_name}
                        className="w-16 h-auto object-contain rounded-none shadow-none"
                      />
                      <span className="text-sm font-medium text-gray-900">
                        {shipment.carrier_name}
                      </span>
                    </>
                  ) : (
                    <span className="text-sm font-medium text-gray-900">
                      {shipment.carrier_name}
                    </span>
                  )}
                </div>
                <div className="flex-grow">
                  <a 
                    href={getTrackingUrl(shipment.carrier_name, shipment.tracking_code)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600 hover:text-blue-800 font-medium inline-flex items-center gap-1"
                  >
                    {shipment.tracking_code}
                    <ExternalLink className="w-3.5 h-3.5" />
                  </a>
                  <p className="text-xs text-gray-500 mt-0.5">
                    {formatDate(shipment.shipment_notification_timestamp)}
                  </p>
                </div>
              </div>
            );
          })}
          {(!orderData.shipments || orderData.shipments.length === 0) && (
            <div className="text-sm text-gray-500 text-center py-4 bg-gray-50 rounded-lg">
              No shipments information available
            </div>
          )}
        </div>

        <div className="mt-8">
          <h3 className="text-sm font-medium text-gray-700 mb-4">Packages</h3>
          <div className="space-y-4">
            {orderData.packages?.length > 0 ? (
              orderData.packages.map((pkg, index) => (
                <PackageBox
                  key={index}
                  pkg={pkg}
                  index={index}
                  isGift={isGiftOrder}
                />
              ))
            ) : (
              <EmptyPackageBox isGift={isGiftOrder} />
            )}
          </div>

          {isGiftOrder && <GiftMessage message={orderData.gift_message} />}
        </div>
      </div>
    </div>
  );
};

export default ShippingInformation;