import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import StoreLimitModal from '../../components/StoreLimitModal';
import StoreDeleteModal from '../../components/StoreDeleteModal';
import Sections from '../../components/Sections';
import ShippingProfiles from '../../components/ShippingProfiles.js';
import { PlusIcon, ShoppingBagIcon, TrashIcon, RefreshIcon, InformationCircleIcon } from '@heroicons/react/outline';
import Dialog from '../../components/ui/Dialog';

const DefaultStoreImage = 'https://app.canvashelper.com/logo192.png';

function Stores({ setSelectedStore }) {
    const [stores, setStores] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [storeToDelete, setStoreToDelete] = useState(null);
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const [activeTab, setActiveTab] = useState('sections');
    const [storeLogos, setStoreLogos] = useState({});
    const navigate = useNavigate();
    const [connectDialogOpen, setConnectDialogOpen] = useState(false);
    const [selectedStoreForConnect, setSelectedStoreForConnect] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState('');
    const [progress, setProgress] = useState(0);

    const handleConnectClick = (store) => {
        setSelectedStoreForConnect(store);
        setConnectDialogOpen(true);
    };

    const handleConnectConfirm = () => {
        if (selectedStoreForConnect) {
            handleReauthorize(selectedStoreForConnect.id);
            setConnectDialogOpen(false);
            setSelectedStoreForConnect(null);
        }
    };


    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://etsyhelper.onrender.com/stores/list', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStores(response.data);
            if (response.data.length > 0) {
                setSelectedStore(response.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching stores:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleAccordion = (storeId) => {
        setSelectedStoreId((prevId) => (prevId === storeId ? null : storeId));
    };

    const handleStoreSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const storeData = { name };
    
            const response = await axios.post('https://etsyhelper.onrender.com/stores/create', storeData, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            if (response.data) {
                // Clear the input field
                setName('');
                
                // Create a new store object with the response data
                const newStore = {
                    id: response.data.shop_id, // Use the returned shop_id
                    name: name,                // Use the submitted name
                    shop_name: name,           // Initialize shop_name with the submitted name
                    // Add any other required initial properties
                };
    
                // Update the stores state immediately
                setStores(prevStores => [...prevStores, newStore]);
                
                // Show success message
                toast.success('Mağaza başarıyla oluşturuldu!');
    
                // If you have a selected store setter, you might want to select the new store
                if (setSelectedStore) {
                    setSelectedStore(response.data.shop_id);
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setLimitExceeded(true);
                setShowModal(true);
            } else {
                console.error('Mağaza oluşturulurken hata:', error);
                toast.error('Mağaza oluşturulamadı.');
            }
        }
    };

    // PKCE için code_challenge oluşturma
    const generateCodeChallenge = async (verifier) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const digest = await crypto.subtle.digest('SHA-256', data);
        return btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    };

    const handleReauthorize = async (storeId) => {
        const clientId = process.env.REACT_APP_ETSY_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_ETSY_REDIRECT_URI;
        const state = Math.random().toString(36).substring(2);
        const scope = 'shops_r shops_w transactions_r listings_w listings_r email_r';
    
        const codeVerifier = Math.random().toString(36).substring(2); // Code Verifier oluşturma
        const codeChallenge = await generateCodeChallenge(codeVerifier); // Code Challenge oluşturma
        localStorage.setItem('codeVerifier', codeVerifier); // Code Verifier'ı sakla
        localStorage.setItem('oauthState', state); // State parametresini sakla
        localStorage.setItem('storeId', storeId); // storeId'yi localStorage'da saklıyoruz
        const authUrl = `https://www.etsy.com/oauth/connect?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        window.location.href = authUrl; // Kullanıcıyı Etsy yetkilendirme sayfasına yönlendiriyoruz
    };

    const confirmRemove = (storeId) => {
        setStoreToDelete(storeId);
        setShowModal(true);
    };

    const handleRemove = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://etsyhelper.onrender.com/stores/delete/${storeToDelete}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            toast.success('Store removed successfully!');
            fetchStores();
            setShowModal(false);
            setStoreToDelete(null);
        } catch (error) {
            console.error('Error removing store:', error);
            toast.error('Failed to remove store.');
            setShowModal(false);
            setStoreToDelete(null);
        }
    };

    const handleUpgradePlan = () => {
        navigate('/my-account/subscription');
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setStoreToDelete(null);
    };

    const handleCloseLimitModal = () => {
        setShowModal(false);
        setLimitExceeded(false);
    };

    const fetchReviews = async (storeId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('JWT token bulunamadı');
            return;
        }
    
        try {
            const response = await fetch(`https://etsyhelper.onrender.com/api/etsy/fetch-reviews`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    store_id: storeId,
                    endpoint: 'getShopReviews',
                    params: {
                        limit: 50,
                        offset: 0
                    }
                })
            });
    
            const reviewsData = await response.json();
    
            if (response.ok) {
                console.log('Yorumlar başarıyla alındı:', reviewsData);
                toast.success(`${reviewsData.results.length} yorum başarıyla alındı`);
            } else {
                throw new Error(reviewsData.message || 'Yorumlar alınamadı');
            }
        } catch (error) {
            console.error('Yorum işlemi sırasında hata:', error);
            toast.error(error.message || 'Yorumlar alınamadı');
        }
    };
    

    const fetchShopInfo = async (storeId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('JWT token bulunamadı');
            return;
        }
    
        try {
            setLoading(true);
            setLoadingStatus('Mağaza bilgileri alınıyor...');
            setProgress(20);
    
            const response = await fetch(`https://etsyhelper.onrender.com/fetch-shop-info?store_id=${storeId}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            });
    
            const data = await response.json();
            if (response.ok) {
                // Shipping Profiles
                setLoadingStatus('Kargo profilleri alınıyor...');
                setProgress(35);
                try {
                    await fetch(`https://etsyhelper.onrender.com/stores/${storeId}/shipping-profiles`, {
                        method: 'GET',
                        headers: { 'Authorization': `Bearer ${token}` },
                    });
                } catch (shippingError) {
                    console.error('Kargo profilleri alınamadı:', shippingError);
                    // Kritik olmadığı için işleme devam ediyoruz
                }
    
                // Return Policies
                setLoadingStatus('İade politikaları alınıyor...');
                setProgress(40);
                try {
                    await fetch(`https://etsyhelper.onrender.com/stores/${storeId}/return-policies`, {
                        method: 'GET',
                        headers: { 'Authorization': `Bearer ${token}` },
                    });
                } catch (returnError) {
                    console.error('İade politikaları alınamadı:', returnError);
                    // Kritik olmadığı için işleme devam ediyoruz
                }
    
                setLoadingStatus('Mağaza siparişleri alınıyor...');
                setProgress(50);
    
                try {
                    const ordersResponse = await fetch(`https://etsyhelper.onrender.com/api/etsy/fetch-orders`, {
                        method: 'POST',
                        headers: { 
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            store_id: storeId,
                            endpoint: 'getShopReceipts',
                            params: {
                                limit: 50,
                                offset: 0
                            }
                        })
                    });
    
                    setLoadingStatus('Siparişler işleniyor...');
                    setProgress(75);
                    
                    const ordersData = await ordersResponse.json();
                    
                    if (ordersResponse.ok) {
                        setLoadingStatus('Yorumlar alınıyor...');
                        setProgress(85);
    
                        // Fetch Reviews
                        await fetchReviews(storeId);
    
                        setLoadingStatus('İşlem tamamlandı');
                        setProgress(100);
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        toast.success(`${ordersData.results.length} sipariş ve ilgili yorumlar başarıyla alındı`);
                        fetchStores();
                    } else {
                        throw new Error(ordersData.message || 'Siparişler alınamadı');
                    }
                } catch (orderError) {
                    console.error('Sipariş işlemi sırasında hata:', orderError);
                    toast.error(orderError.message || 'Siparişler alınamadı');
                }
            } else {
                console.error('Mağaza bilgisi alınamadı:', data.message);
                toast.error('Mağaza bilgisi alınamadı.');
            }
        } catch (error) {
            console.error('İstek sırasında hata oluştu:', error);
            toast.error('Mağaza bilgileri alınamadı.');
        } finally {
            setLoading(false);
            setLoadingStatus('');
            setProgress(0);
        }
    };
    

    const renderTabsContent = (storeId) => {
        if (activeTab === 'sections') {
            return <Sections storeId={storeId} />;
        } else if (activeTab === 'shopInfo') {
            return <div>Store details and shop info for store {storeId}.</div>;
        } else if (activeTab === 'shippingProfiles') {
            return <ShippingProfiles storeId={storeId} />;  // ShippingProfiles bileşenini burada render ediyoruz
        }
    };
    

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="bg-white rounded-2xl shadow-sm p-6 mb-8">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                        Mağaza Yönetimi
                    </h1>

                    {/* Add Store Form */}
                    <form onSubmit={handleStoreSubmit} className="space-y-4">
                        <div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Mağaza Adı
                            </label>
                            <div className="flex gap-4">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    className="flex-1 block w-full rounded-lg border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                                    placeholder="Mağazanızın adını girin"
                                />
                                <button
                                    type="submit"
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    <PlusIcon className="w-5 h-5 mr-2" />
                                    Mağaza Ekle
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Stores List */}
            <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900">Mağazalarınız</h2>
                
                {loading ? (
                    <div className="flex items-center justify-center h-40">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                    </div>
                ) : stores.length === 0 ? (
                    <div className="bg-gray-50 rounded-xl p-8 text-center">
                        <ShoppingBagIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-medium text-gray-900">Mağaza Bulunamadı</h3>
                        <p className="mt-1 text-sm text-gray-500">Yeni bir mağaza ekleyerek başlayın.</p>
                    </div>
                ) : (
                    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-1">
                        {stores.map((store) => (
                            <div key={store.id} className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100" onClick={() => toggleAccordion(store.id)}>
                                <div className="p-6">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <div className="w-16 h-16 flex-shrink-0">
                                                <img
                                                    src={store.shop_logo_url || DefaultStoreImage}
                                                    alt={store.shop_name}
                                                    className="w-full h-full object-cover rounded-lg cursor-pointer hover:opacity-80 transition-opacity duration-200"
                                                    onClick={(e) => {
                                                        e.stopPropagation();  // Akordeon açılmasını önlemek için
                                                        window.open(store.shop_url, '_blank');  // Yeni sekmede aç
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <h3 className="text-lg font-medium text-gray-900">
                                                    {store.shop_name || "Mağaza Adı Bekleniyor"}
                                                </h3>
                                                <p className="text-sm text-gray-500">
                                                    {store.shop_id ? `Etsy Shop ID: ${store.shop_id}` : "Mağaza Bilgileri Alınmadı"}
                                                </p>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => toggleAccordion(store.id)}
                                            className="p-2 text-gray-400 hover:text-gray-500"
                                        >
                                            <svg
                                                className={`w-6 h-6 transform transition-transform ${
                                                    selectedStoreId === store.id ? 'rotate-180' : ''
                                                }`}
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </button>
                                    </div>

                                    {selectedStoreId === store.id && (
                                        <div className="mt-6" onClick={(e) => e.stopPropagation()} >
                                            {/* Tabs */}
                                            <div className="border-b border-gray-200">
                                                <nav className="-mb-px flex space-x-8">
                                                    {['sections', 'shopInfo', 'shippingProfiles'].map((tab) => (
                                                        <button
                                                            key={tab}
                                                            onClick={() => setActiveTab(tab)}
                                                            className={`${
                                                                activeTab === tab
                                                                    ? 'border-purple-500 text-purple-600'
                                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm capitalize`}
                                                        >
                                                            {tab === 'shippingProfiles' ? 'Shipping & Return Profiles' : tab.replace(/([A-Z])/g, ' $1').trim()}
                                                        </button>
                                                    ))}
                                                </nav>
                                            </div>

                                            {/* Tab Content */}
                                            <div className="mt-6 bg-gray-50 rounded-lg p-4">
                                                {renderTabsContent(store.id)}
                                            </div>

                                            {/* Action Buttons */}
                                            <div className="mt-6 flex flex-wrap gap-4">
                                                <button
                                                    onClick={() => handleConnectClick(store)}
                                                    className={`inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white ${
                                                        !store.shop_id 
                                                        ? 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-500' 
                                                        : 'bg-purple-500 hover:bg-purple-600 focus:ring-purple-500'
                                                    } focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                                >
                                                    <RefreshIcon className="w-5 h-5 mr-2" />
                                                    {!store.shop_id ? 'Connect Etsy' : 'Yeniden Yetkilendir'}
                                                </button>
                                                <button
                                                    onClick={() => fetchShopInfo(store.id)}
                                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                >
                                                    <InformationCircleIcon className="w-5 h-5 mr-2" />
                                                    Mağaza Bilgilerini Al
                                                </button>
                                                <button
                                                    onClick={() => confirmRemove(store.id)}
                                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                >
                                                    <TrashIcon className="w-5 h-5 mr-2" />
                                                    Mağazayı Kaldır
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {loadingStatus && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
                        <div className="text-center mb-4">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600 mx-auto mb-4"></div>
                            <div className="text-lg font-medium text-gray-900 mb-2">
                                {loadingStatus}
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                                <div 
                                    className="bg-purple-600 h-2 rounded-full transition-all duration-300" 
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                            <div className="text-sm text-gray-500">
                                {progress}% tamamlandı
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Mağaza Bağlama/Yeniden Yetkilendirme Dialog'u */}
            <Dialog
                isOpen={connectDialogOpen}
                onClose={() => {
                    setConnectDialogOpen(false);
                    setSelectedStoreForConnect(null);
                }}
                onConfirm={handleConnectConfirm}
                title={selectedStoreForConnect?.shop_id ? 'Mağaza Yeniden Yetkilendirme' : 'Connect Etsy'}
                description={
                    selectedStoreForConnect?.shop_id
                        ? `${selectedStoreForConnect?.shop_name || 'Seçili mağaza'} mağazasını yeniden yetkilendirmek istediğinize emin misiniz? Bu işlem mevcut bağlantıyı güncelleyecektir.`
                        : `${selectedStoreForConnect?.shop_name || selectedStoreForConnect?.name || 'Seçili mağaza'} mağazasına bağlanmak için Etsy'ye yönlendirileceksiniz. Devam etmek istiyor musunuz?`
                }
                confirmText={selectedStoreForConnect?.shop_id ? 'Yetkilendir' : 'Connect'}
            />

            <StoreLimitModal
                isVisible={limitExceeded}
                onClose={handleCloseLimitModal}
                onUpgrade={handleUpgradePlan}
            />

            <StoreDeleteModal
                isVisible={storeToDelete !== null}
                onClose={handleCloseModal}
                onConfirm={handleRemove}
            />
        </div>
    );
}
export default Stores;


