import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function StoreProfiles({ storeId }) {
    const [shippingProfiles, setShippingProfiles] = useState([]);
    const [returnPolicies, setReturnPolicies] = useState([]);
    const [loading, setLoading] = useState({
        shipping: false,
        return: false
    });

    useEffect(() => {
        fetchShippingProfiles();
        fetchReturnPolicies();
    }, [storeId]);

    const fetchShippingProfiles = async () => {
        setLoading(prev => ({ ...prev, shipping: true }));
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/shipping-profiles`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                setShippingProfiles(response.data.shipping_profiles);
                toast.success('Shipping profiles fetched successfully!');
            }
        } catch (error) {
            console.error('Error fetching shipping profiles:', error);
            toast.error('Error fetching shipping profiles.');
        } finally {
            setLoading(prev => ({ ...prev, shipping: false }));
        }
    };

    const fetchReturnPolicies = async () => {
        setLoading(prev => ({ ...prev, return: true }));
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/return-policies`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                setReturnPolicies(response.data.return_policies);
                toast.success('Return policies fetched successfully!');
            }
        } catch (error) {
            console.error('Error fetching return policies:', error);
            toast.error('Error fetching return policies.');
        } finally {
            setLoading(prev => ({ ...prev, return: false }));
        }
    };

    const LoadingSpinner = () => (
        <div className="flex items-center justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
    );

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Shipping Profiles Section */}
                <div className="bg-white rounded-lg shadow p-6">
                    <h2 className="text-xl font-bold mb-4">Shipping Profiles</h2>
                    {loading.shipping ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="space-y-4">
                            {shippingProfiles.length > 0 ? (
                                shippingProfiles.map((profile) => (
                                    <div
                                        key={profile.shipping_profile_id}
                                        className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
                                    >
                                        <h3 className="font-semibold text-lg mb-2">{profile.title}</h3>
                                        <div className="grid grid-cols-2 gap-4 text-sm">
                                            <div>
                                                <p className="text-gray-600">Min Processing Days</p>
                                                <p className="font-medium">{profile.min_processing_days || 'N/A'}</p>
                                            </div>
                                            <div>
                                                <p className="text-gray-600">Max Processing Days</p>
                                                <p className="font-medium">{profile.max_processing_days || 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center text-gray-500 py-4">
                                    <p>No shipping profiles available.</p>
                                    <p className="text-sm mt-2">Please add a shipping profile in your Etsy shop settings.</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Return Policies Section */}
                <div className="bg-white rounded-lg shadow p-6">
                    <h2 className="text-xl font-bold mb-4">Return Policies</h2>
                    {loading.return ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="space-y-4">
                            {returnPolicies.length > 0 ? (
                                returnPolicies.map((policy) => (
                                    <div
                                        key={policy.return_policy_id}
                                        className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
                                    >
                                        <div className="space-y-3">
                                            <div>
                                                <p className="text-gray-600">Returns Accepted</p>
                                                <p className="font-medium">{policy.accepts_returns ? 'Yes' : 'No'}</p>
                                            </div>
                                            <div>
                                                <p className="text-gray-600">Exchanges Accepted</p>
                                                <p className="font-medium">{policy.accepts_exchanges ? 'Yes' : 'No'}</p>
                                            </div>
                                            <div>
                                                <p className="text-gray-600">Return Period</p>
                                                <p className="font-medium">{policy.return_deadline} days</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center text-gray-500 py-4">
                                    <p>No return policies available.</p>
                                    <p className="text-sm mt-2">Please add a return policy in your Etsy shop settings.</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default StoreProfiles;