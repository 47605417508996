import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import ProductEditModal from './ProductEditModal';
import DeleteConfirmationDialog from '../components/ui/DeleteConfirmationDialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Plus, Filter, SortAsc, SortDesc, Wand2, Grid, Trash2, Edit2, CheckSquare, ChevronDown, Loader2, Image } from 'lucide-react';
import { quantum } from 'ldrs'; // uiball yükleme animasyonu
quantum.register();

function DraftProducts() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [variationProfileId, setVariationProfileId] = useState('');
  const [generatedData, setGeneratedData] = useState([]);
  const [variationProfiles, setVariationProfiles] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [sortOrder, setSortOrder] = useState('desc');
  const [showModal, setShowModal] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [loading, setLoading] = useState(false); // Loader için state
  const [sections, setSections] = useState([]);
  const [productTips, setProductTips] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 0,
    total_count: 0,
    has_next: false,
    has_prev: false
  });


  const apiKey = process.env.REACT_APP_OPENAI;

  // Fetch products
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      const params = new URLSearchParams({
        page: currentPage,
        status: statusFilter,
        sort_order: sortOrder,
        ...(variationProfileId && { variation_profile_id: variationProfileId })
      });
  
      const response = await axios.get(`https://etsyhelper.onrender.com/products?${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setProducts(response.data.products);
      setPagination(response.data.pagination);
      
    } catch (error) {
      console.error('Error fetching products:', error);
      toast.error('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };
    // Fetch variation profiles
  const fetchVariationProfiles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.canvashelper.com/api/variation_profiles', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVariationProfiles(response.data);
    } catch (error) {
      console.error('Error fetching variation profiles:', error);
    }
  };

  const fetchSections = async () => {
    try {
      const token = localStorage.getItem('token');
      const storeId = localStorage.getItem('last_selected_store');
      const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/sections-from-db`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSections(response.data.sections);
    } catch (error) {
      console.error('Failed to fetch sections:', error);
      toast.error('Failed to fetch sections');
    }
  };

  useEffect(() => {
    fetchVariationProfiles();
    fetchSections();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [currentPage, statusFilter, sortOrder, variationProfileId]);

  // Helper functions
  const getVariationProfileName = (variationProfileId) => {
    const profile = variationProfiles.find((p) => p.id === variationProfileId);
    return profile ? profile.name : 'No Profile';
  };

  const getSectionTitle = (sectionId) => {
    const section = sections.find(s => s.id === sectionId);
    return section ? section.title : 'No Section';
  };

  const toggleSelectProduct = (productId) => {
    setSelectedProducts(prev => 
      prev.includes(productId) 
        ? prev.filter(id => id !== productId)
        : [...prev, productId]
    );
  };

  const selectAllProducts = () => {
    setSelectedProducts(
      selectedProducts.length === products.length
        ? []
        : products.map(product => product.id)
    );
  };
  

  const assignSections = async () => {
    if (selectedProducts.length === 0) {
      toast.warning('Please select products first');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const storeId = localStorage.getItem('last_selected_store');

      const response = await axios.post('https://etsyhelper.onrender.com/assign-sections', {
        store_id: storeId,
        product_ids: selectedProducts,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.updated_products_info) {
        await fetchProducts();
        setSelectedProducts([]);
        toast.success('Sections assigned successfully');
      }
    } catch (error) {
      console.error('Error assigning sections:', error);
      toast.error('Failed to assign sections');
    } finally {
      setLoading(false);
    }
  };

  const handleTipsChange = (productId, value) => {
    setProductTips(prev => ({
      ...prev,
      [productId]: value
    }));
  };

  const handleBulkMockupGeneration = async () => {
    if (selectedProducts.length === 0) {
      toast.warning('Please select products first');
      return;
    }
  
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      let successCount = 0;
  
      // Her ürün için ayrı işlem yap
      for (const productId of selectedProducts) {
        try {
          // İlgili ürünü products array'inden bul
          const product = products.find(p => p.id === productId);
          
          // Eğer ürünün mockup'ı varsa önce sil
          if (product && product.mockup_images && product.mockup_images.length > 0) {
            await axios.post('https://etsyhelper.onrender.com/delete_mockups', 
              { product_id: productId }, 
              { headers: { Authorization: `Bearer ${token}` }}
            );
          }
          
          // Yeni mockup oluştur
          const response = await axios.post('https://etsyhelper.onrender.com/generate_mockup', 
            { product_id: productId }, 
            { headers: { Authorization: `Bearer ${token}` }}
          );
  
          // Response kontrolü
          if (response.data.mockup_images) {
            successCount++;
          }
        } catch (error) {
          console.error(`Error processing product ${productId}:`, error);
        }
      }
  
      // İşlem sonucu kontrolü
      if (successCount === selectedProducts.length) {
        toast.success(`Successfully generated mockups for all ${successCount} products`);
      } else if (successCount > 0) {
        toast.info(`Generated mockups for ${successCount} out of ${selectedProducts.length} products`);
      }
  
      // Ürünleri yeniden yükle
      await fetchProducts();
  
    } catch (error) {
      console.error('Error in bulk mockup generation:', error);
      toast.error('Failed to generate mockups');
    } finally {
      setLoading(false);
    }
  };

  const generateProductData = async () => {
    if (selectedProducts.length === 0) {
        toast.warning('Please select products first');
        return;
    }

    try {
        setLoading(true);
        const token = localStorage.getItem('token');

        // Tüm ürün verilerini hazırla
        const allProductData = selectedProducts.map(productId => {
            const product = products.find(p => p.id === productId);
            return {
                id: productId,
                image_url: product.base_image,
                tips: productTips[productId] || ''
            };
        }).filter(product => product.image_url);

        if (allProductData.length === 0) {
            toast.error('No valid products to process');
            return;
        }

        // 1. İsimleri oluştur
        const nameResponse = await axios.post('https://etsyhelper.onrender.com/generate', {
            products: allProductData
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        if (nameResponse.data.error) {
            throw new Error(nameResponse.data.error);
        }

        // Başarılı sonuçları filtrele
        const successfulProducts = nameResponse.data.filter(result => result.success);
        const failedProducts = nameResponse.data.filter(result => !result.success);

        // Başarısız ürünleri logla
        if (failedProducts.length > 0) {
            console.log('Failed to generate names for:', failedProducts);
            toast.warning(`Failed to generate names for ${failedProducts.length} products`);
        }

        // İsim üretimi başarılı olan ürünler için description ve tags oluştur
        if (successfulProducts.length > 0) {
            toast.success(`Generated names for ${successfulProducts.length} products`);

            // 2. Description'ları oluştur
            try {
                const descriptionResponse = await axios.post('https://etsyhelper.onrender.com/generate-product-description', {
                    products: successfulProducts.map(product => ({
                        product_id: product.product_id,
                        name: product.title
                    }))
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const successfulDescriptions = descriptionResponse.data.filter(r => r.success);
                const failedDescriptions = descriptionResponse.data.filter(r => !r.success);

                if (failedDescriptions.length > 0) {
                    console.log('Failed to generate descriptions for:', failedDescriptions);
                    toast.warning(`Failed to generate descriptions for ${failedDescriptions.length} products`);
                }

                if (successfulDescriptions.length > 0) {
                    toast.success(`Generated descriptions for ${successfulDescriptions.length} products`);
                }

                // 3. Tag'leri oluştur
                const tagsResponse = await axios.post('https://etsyhelper.onrender.com/generate-product-tags', {
                    products: successfulProducts.map(product => ({
                        product_id: product.product_id,
                        name: product.title
                    }))
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const successfulTags = tagsResponse.data.filter(r => r.success);
                const failedTags = tagsResponse.data.filter(r => !r.success);

                if (failedTags.length > 0) {
                    console.log('Failed to generate tags for:', failedTags);
                    toast.warning(`Failed to generate tags for ${failedTags.length} products`);
                }

                if (successfulTags.length > 0) {
                    toast.success(`Generated tags for ${successfulTags.length} products`);
                }

                // Ürünleri güncelle
                const updatedProducts = products.map(product => {
                    const nameResult = successfulProducts.find(r => r.product_id === product.id);
                    if (!nameResult) return product;

                    const descResult = successfulDescriptions.find(r => r.product_id === product.id);
                    const tagResult = successfulTags.find(r => r.product_id === product.id);

                    return {
                        ...product,
                        name: nameResult.title,
                        description: descResult?.description || product.description,
                        tags: tagResult?.tags || product.tags
                    };
                });

                setProducts(updatedProducts);

            } catch (error) {
                console.error('Error in sequential requests:', error);
                toast.error('Error processing descriptions and tags');
            }
        }

        // State'leri temizle ve güncel verileri getir
        setProductTips({});
        await fetchProducts();

        // Final özet
        toast.info('Processing completed', {
            autoClose: 3000,
        });

    } catch (error) {
        console.error('Error in generateProductData:', error);
        toast.error('Failed to generate product data');
    } finally {
        setLoading(false);
    }
};

const SingleProductMagic = ({ product, onUpdate, productTips }) => {
  const [processing, setProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);

  const generateSingleProduct = async () => {
    if (processing) return;
    
    try {
      setProcessing(true);
      const token = localStorage.getItem('token');

      const productTip = productTips[product.id] || '';

      // 1. İsim oluştur
      setCurrentStep('name');
      const nameResponse = await axios.post('https://etsyhelper.onrender.com/generate-single', {
          id: product.id,
          image_url: product.base_image,
          tips: productTip
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!nameResponse.data.success) {
        throw new Error('Name generation failed');
      }

      // 2. Açıklama oluştur
      setCurrentStep('description');
      await axios.post('https://etsyhelper.onrender.com/generate-single-product-description', {
          id: product.id,
          name: nameResponse.data.title
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // 3. Etiketler oluştur
      setCurrentStep('tags');
      await axios.post('https://etsyhelper.onrender.com/generate-single-product-tags', {
          id: product.id,
          name: nameResponse.data.title
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      toast.success('All content generated successfully');
      onUpdate && onUpdate();

    } catch (error) {
      console.error('Error in magic generation:', error);
      toast.error(`Failed at ${currentStep} generation`);
    } finally {
      setProcessing(false);
      setCurrentStep(null);
    }
  };

  const getLoadingMessage = () => {
    switch (currentStep) {
      case 'name':
        return 'Generating name...';
      case 'description':
        return 'Writing description...';
      case 'tags':
        return 'Creating tags...';
      default:
        return 'Processing...';
    }
  };

  return (
    <button
      onClick={generateSingleProduct}
      disabled={processing}
      className={`
        flex items-center gap-2 px-3 py-1.5 rounded-md text-xs font-medium
        ${processing 
          ? 'bg-purple-100 text-purple-800' 
          : 'bg-purple-600 text-white hover:bg-purple-700'
        }
        transition-colors
        disabled:opacity-50
      `}
    >
      {processing ? (
        <>
          <div className="animate-spin">
            <Loader2 size={14} />
          </div>
          <span>{getLoadingMessage()}</span>
        </>
      ) : (
        <>
          <Wand2 size={14} />
          <span>Magic</span>
        </>
      )}
    </button>
  );
};

  const openEditModal = (productId) => {
    setEditingProductId(productId);
    setShowModal(true);
  };

  const handleDeleteClick = (productId) => {
    setProductToDelete(productId);
    setDeleteModalOpen(true);
  };
  
  const handleDeleteConfirm = async () => {
    if (productToDelete) {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        await axios.delete(`https://etsyhelper.onrender.com/products/${productToDelete}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await fetchProducts();
        toast.success('Product deleted successfully');
      } catch (error) {
        console.error('Error deleting product:', error);
        toast.error('Failed to delete product');
      } finally {
        setLoading(false);
        setDeleteModalOpen(false);
        setProductToDelete(null);
      }
    }
  };
  
  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setProductToDelete(null);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-7xl mx-auto">
        {/* Action Bar - Desktop */}
        <div className="hidden sm:block bg-white p-4 rounded-lg shadow-sm mb-4">
          <div className="flex flex-wrap gap-4 items-center justify-between">
            {/* Left Side Actions */}
            <div className="flex gap-2">
              <button 
                onClick={selectAllProducts}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm border rounded-md hover:bg-gray-50"
              >
                <CheckSquare size={16} />
                {selectedProducts.length === products.length ? 'Deselect All' : 'Select All'}
              </button>
              
              <button 
                onClick={generateProductData}
                disabled={selectedProducts.length === 0}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm bg-purple-600 text-white rounded-md hover:bg-purple-700 disabled:opacity-50"
              >
                <Wand2 size={16} />
                Generate Content
              </button>
  
              <button
                onClick={assignSections}
                disabled={selectedProducts.length === 0}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
              >
                <Grid size={16} />
                Assign Sections
              </button>
              <button
                onClick={handleBulkMockupGeneration}
                disabled={selectedProducts.length === 0}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm bg-green-600 text-white rounded-md hover:bg-green-700 disabled:opacity-50"
              >
                <Image size={16} />
                Generate Mockup
              </button>
            </div>
  
            {/* Right Side Filters */}
            <div className="flex gap-2">
              <select
                value={variationProfileId}
                onChange={(e) => setVariationProfileId(e.target.value)}
                className="px-3 py-1.5 border rounded-md text-sm"
              >
                <option value="">All Profiles</option>
                {variationProfiles.map(profile => (
                  <option key={profile.id} value={profile.id}>{profile.name}</option>
                ))}
              </select>
  
              <div className="filter flex rounded-md overflow-hidden border">
                <button 
                  onClick={() => setStatusFilter('all')}
                  className={`px-3 py-1.5 text-sm ${statusFilter === 'all' ? 'bg-gray-100' : 'bg-white'}`}
                >
                  All
                </button>
                <button 
                  onClick={() => setStatusFilter('preparing')}
                  className={`px-3 py-1.5 text-sm ${statusFilter === 'preparing' ? 'bg-yellow-50 text-yellow-700' : 'bg-white'}`}
                >
                  Preparing
                </button>
                <button 
                  onClick={() => setStatusFilter('ready')}
                  className={`px-3 py-1.5 text-sm ${statusFilter === 'ready' ? 'bg-green-50 text-green-700' : 'bg-white'}`}
                >
                  Ready
                </button>
              </div>
  
              <button
                onClick={() => setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc')}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm border rounded-md hover:bg-gray-50"
              >
                {sortOrder === 'desc' ? <SortDesc size={16} /> : <SortAsc size={16} />}
                {sortOrder === 'desc' ? 'Latest' : 'Oldest'}
              </button>
            </div>
          </div>
        </div>
  
        {/* Action Bar - Mobile */}
        <div className="sm:hidden bg-white rounded-lg shadow-sm mb-4">
          {/* Primary Actions */}
          <div className="p-3 grid grid-cols-3 gap-2">
            <button 
              onClick={selectAllProducts}
              className="flex items-center justify-center px-2 py-1.5 text-xs border rounded-md hover:bg-gray-50"
            >
              <CheckSquare size={14} />
            </button>
            
            <button 
              onClick={generateProductData}
              disabled={selectedProducts.length === 0}
              className="flex items-center justify-center gap-1.5 px-2 py-1.5 text-xs bg-purple-600 text-white rounded-md hover:bg-purple-700 disabled:opacity-50"
            >
              <Wand2 size={14} />
              <span className="text-xs">Magic</span>
            </button>

            <button
              onClick={assignSections}
              disabled={selectedProducts.length === 0}
              className="flex items-center justify-center gap-1.5 px-2 py-1.5 text-xs bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
            >
              <Grid size={14} />
              <span className="text-xs">Sections</span>
            </button>
            <button
              onClick={handleBulkMockupGeneration}
              disabled={selectedProducts.length === 0}
              className="flex items-center justify-center gap-1.5 px-2 py-1.5 text-xs bg-green-600 text-white rounded-md hover:bg-green-700 disabled:opacity-50"
            >
              <Image size={14} />
              <span className="text-xs">Mockup</span>
            </button>
          </div>
  
          {/* Filter Toggle Button */}
          <div className="border-t px-3 py-2">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center justify-between w-full text-sm text-gray-600"
            >
              <span className="flex items-center gap-2">
                <Filter size={16} />
                Filters & Sort
              </span>
              <ChevronDown
                size={16}
                className={`transform transition-transform ${showFilters ? 'rotate-180' : ''}`}
              />
            </button>
          </div>
  
          {/* Expandable Filters */}
          {showFilters && (
            <div className="border-t p-3 space-y-3">
              <select
                value={variationProfileId}
                onChange={(e) => setVariationProfileId(e.target.value)}
                className="w-full px-3 py-1.5 border rounded-md text-sm"
              >
                <option value="">All Profiles</option>
                {variationProfiles.map(profile => (
                  <option key={profile.id} value={profile.id}>{profile.name}</option>
                ))}
              </select>
  
              <div className="grid grid-cols-3 gap-1 rounded-md overflow-hidden border">
                <button 
                  onClick={() => setStatusFilter('all')}
                  className={`px-3 py-1.5 text-sm ${statusFilter === 'all' ? 'bg-gray-100' : 'bg-white'}`}
                >
                  All
                </button>
                <button 
                  onClick={() => setStatusFilter('preparing')}
                  className={`px-3 py-1.5 text-sm ${statusFilter === 'preparing' ? 'bg-yellow-50 text-yellow-700' : 'bg-white'}`}
                >
                  Preparing
                </button>
                <button 
                  onClick={() => setStatusFilter('ready')}
                  className={`px-3 py-1.5 text-sm ${statusFilter === 'ready' ? 'bg-green-50 text-green-700' : 'bg-white'}`}
                >
                  Ready
                </button>
              </div>
  
              <button
                onClick={() => setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc')}
                className="flex items-center justify-center gap-1.5 px-3 py-1.5 text-sm border rounded-md hover:bg-gray-50 w-full"
              >
                {sortOrder === 'desc' ? <SortDesc size={16} /> : <SortAsc size={16} />}
                {sortOrder === 'desc' ? 'Latest First' : 'Oldest First'}
              </button>
            </div>
          )}
        </div>
  
        {/* Main Content */}
        {products.length === 0 ? (
          <div className="bg-white rounded-lg shadow-sm p-8 text-center">
            <div className="flex flex-col items-center gap-3">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center">
                <Grid size={24} className="text-gray-400" />
              </div>
              <h3 className="text-sm font-medium text-gray-900">No Products Found</h3>
              <p className="text-xs text-gray-500">There are no products matching your filters.</p>
            </div>
          </div>
        ) : (
          <div>
            {/* Products Grid */}
            <div className="grid gap-3">
              {products.map((product) => (
                <div
                  key={product.id}
                  className={`bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow ${
                    product.name && product.description && product.tags && product.section_id && product.images 
                      ? 'border-l-4 border-green-500' 
                      : 'border-l-4 border-yellow-500'
                  }`}
                >
                  <div className="p-3">
                    {/* Status and Actions Row */}
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={selectedProducts.includes(product.id)}
                          onChange={() => toggleSelectProduct(product.id)}
                          className="h-3.5 w-3.5 rounded border-gray-300"
                        />
                        <span className={`px-1.5 py-0.5 rounded-full text-xs font-medium ${
                          product.name && product.description && product.tags && product.section_id && product.images
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {product.name && product.description && product.tags && product.section_id && product.images ? 'Ready' : 'Preparing'}
                        </span>
                      </div>
  
                      <div className="flex gap-1">
                      <SingleProductMagic 
                          product={product} 
                          onUpdate={fetchProducts}
                          loading={loading}
                          productTips={productTips}
                        />
                        <button
                          onClick={() => openEditModal(product.id)}
                          className="p-1 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-md"
                        >
                          <Edit2 size={14} />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(product.id)}
                          className="p-1 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-md"
                        >
                          <Trash2 size={14} />
                        </button>
                      </div>
                    </div>
  
                    {/* Main Content Row */}
                    <div className="flex gap-3">
                      {/* Image Column */}
                      <div className="w-[80px] flex-none">
                        <div className="w-[80px] h-[80px] bg-gray-50 rounded-lg border border-gray-200">
                          <img
                            src={product.base_image}
                            alt={product.name || 'Product'}
                            className="w-full h-full object-contain rounded-lg"
                          />
                        </div>
                        <input
                          type="text"
                          value={productTips[product.id] || ''}
                          onChange={(e) => handleTipsChange(product.id, e.target.value)}
                          placeholder="Tips"
                          className="mt-1 w-full text-xs p-1 border rounded-md text-[11px]"
                        />
                      </div>
  
                      {/* Content Column */}
                      <div className="flex-grow min-w-0 space-y-1.5">
                        <h3 className="text-xs font-medium text-gray-900 break-words leading-tight">
                          {product.name || 'Untitled Product'}
                        </h3>
                        
                        <p className="text-xs text-gray-600 line-clamp-2 leading-snug">
                          {product.description || 'No description available'}
                        </p>
  
                        <div className="flex flex-wrap gap-1">
                          {product.tags
                            ? product.tags.split(',').map((tag, idx) => (
                                <span
                                  key={idx}
                                  className="px-1.5 py-0.5 bg-gray-100 text-gray-600 text-[10px] rounded whitespace-nowrap"
                                >
                                  {tag.trim()}
                                </span>
                              ))
                            : <span className="text-gray-400 text-[10px]">No tags</span>
                          }
                        </div>
  
                        <div className="flex flex-wrap gap-2 text-[10px]">
                          <span className="text-gray-600">
                            Section: <span className="font-medium">{getSectionTitle(product.section_id)}</span>
                          </span>
                          <span className="text-gray-600">
                            Profile: <span className="font-medium">{getVariationProfileName(product.variation_profile_id)}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
  
            {/* Pagination */}
            {pagination.total_pages > 1 && (
              <div className="mt-6 flex flex-col sm:flex-row justify-center items-center gap-2">
                <button
                  onClick={() => setCurrentPage(prev => prev - 1)}
                  disabled={!pagination.has_prev}
                  className="w-full sm:w-auto px-4 py-2 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Previous
                </button>
  
                <div className="flex gap-2 overflow-x-auto">
                  {Array.from({ length: pagination.total_pages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => setCurrentPage(i + 1)}
                      className={`px-4 py-2 border rounded-md ${
                        currentPage === i + 1 
                          ? 'bg-blue-600 text-white' 
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
  
                <button
                  onClick={() => setCurrentPage(prev => prev + 1)}
                  disabled={!pagination.has_next}
                  className="w-full sm:w-auto px-4 py-2 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}
      {/* Loading Overlay */}
      {loading && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm z-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-xl shadow-lg flex flex-col items-center gap-3">
            <l-quantum size="45" speed="1.75" color="black"></l-quantum>
            <p className="text-sm text-gray-600">Processing...</p>
          </div>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-lg">
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {/* Edit Modal */}
      <ProductEditModal
        productId={editingProductId}
        showModal={showModal}
        setShowModal={setShowModal}
        onProductUpdate={fetchProducts}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        isOpen={deleteModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      {/* Toasts */}
      <ToastContainer />
    </div>
  </div>
  );
}

export default DraftProducts;