import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loader2, ChevronDown, ChevronUp } from 'lucide-react';

function CustomerVariations({ profileId }) {
  const [variations, setVariations] = useState([]);
  const [friendlyDimensionNames, setFriendlyDimensionNames] = useState({});
  const [friendlyFrameNames, setFriendlyFrameNames] = useState({});
  const [prices, setPrices] = useState({});
  const [randomQuantity, setRandomQuantity] = useState({ min: 1, max: 10 });
  const [quantities, setQuantities] = useState({});
  const [deactivated, setDeactivated] = useState({});
  const [isRandomActive, setIsRandomActive] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState('cm');
  const [isSaving, setIsSaving] = useState(false);
  const [profitMargin, setProfitMargin] = useState(30);
  const [supplierVariations, setSupplierVariations] = useState([]);
  const [platformCommission, setPlatformCommission] = useState(20);
  const [storeDiscount, setStoreDiscount] = useState(0);

  const availableUnits = [
    { value: 'cm', label: 'Centimeters (cm)' },
    { value: 'inches', label: 'Inches (in)' }
  ];

  const handlePriceChange = (id, value) => {
    if (value === '' || value === undefined) {
      setPrices({ ...prices, [id]: '' });
      return;
    }

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;
    if (numericValue < 0) return;

    const formattedValue = Math.round(numericValue * 100) / 100;
    setPrices({ ...prices, [id]: formattedValue });
  };

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? '0.00' : number.toFixed(2);
  };

  const sortDimensions = (variations) => {
    return variations.sort((a, b) => {
      const [aWidth] = a.dimension_name.split('x').map(num => parseInt(num));
      const [bWidth] = b.dimension_name.split('x').map(num => parseInt(num));
      return aWidth - bWidth;
    });
  };

  const useOriginalNames = () => {
    const newFriendlyDimensionNames = {};
    const newFriendlyFrameNames = {};

    variations.forEach((variation) => {
      if (!friendlyDimensionNames[variation.id]) {
        newFriendlyDimensionNames[variation.id] = variation.dimension_name;
      }
      if (!friendlyFrameNames[variation.id]) {
        newFriendlyFrameNames[variation.id] = variation.frame_name;
      }
    });

    setFriendlyDimensionNames({
      ...friendlyDimensionNames,
      ...newFriendlyDimensionNames
    });
    setFriendlyFrameNames({
      ...friendlyFrameNames,
      ...newFriendlyFrameNames
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('No token found');
      return;
    }

    // Varyasyonları getir
    axios
      .get(`https://api.canvashelper.com/api/get-variations/${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const sortedVariations = sortDimensions(response.data);
        setVariations(sortedVariations);
      })
      .catch((error) => {
        console.error('Error fetching variations:', error);
      });

    // Supplier varyasyonlarını getir
    axios
      .get(`https://api.canvashelper.com/api/get-supplier-variation/${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setSupplierVariations(response.data);
      })
      .catch((error) => {
        console.error('Error fetching supplier variations:', error);
      });

    // Kaydedilmiş varyasyonları getir
    axios
      .get(`https://api.canvashelper.com/api/get-saved-customer-variations/${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const savedVariations = response.data;
        if (savedVariations.length > 0) {
          setSelectedUnit(savedVariations[0].unit);
        }
        
        const friendlyDims = {};
        const friendlyFrms = {};
        const pricesData = {};
        const quantitiesData = {};
        const deactivatedData = {};

        savedVariations.forEach((savedVariation) => {
          friendlyDims[savedVariation.variation_id] = savedVariation.friendly_dimension_name || '';
          friendlyFrms[savedVariation.variation_id] = savedVariation.friendly_frame_name || '';
          pricesData[savedVariation.variation_id] = savedVariation.price || '';
          quantitiesData[savedVariation.variation_id] = savedVariation.quantity || '';
          deactivatedData[savedVariation.variation_id] = !savedVariation.is_enabled;
        });

        setFriendlyDimensionNames(friendlyDims);
        setFriendlyFrameNames(friendlyFrms);
        setPrices(pricesData);
        setQuantities(quantitiesData);
        setDeactivated(deactivatedData);
      })
      .catch((error) => {
        console.error('Error fetching saved customer variations:', error);
      });
  }, [profileId]);

  const generateRandomQuantity = () => {
    const min = parseInt(randomQuantity.min, 10);
    const max = parseInt(randomQuantity.max, 10);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleRandomCheckbox = () => {
    setIsRandomActive(!isRandomActive);
    if (!isRandomActive) {
      const updatedQuantities = variations.reduce((acc, variation) => {
        acc[variation.id] = generateRandomQuantity();
        return acc;
      }, {});
      setQuantities(updatedQuantities);
    } else {
      setQuantities({});
    }
  };

  useEffect(() => {
    if (isRandomActive) {
      const updatedQuantities = variations.reduce((acc, variation) => {
        acc[variation.id] = generateRandomQuantity();
        return acc;
      }, {});
      setQuantities(updatedQuantities);
    }
  }, [randomQuantity.min, randomQuantity.max]);

  const handleQuantityChange = (id, value) => {
    setQuantities({ ...quantities, [id]: value });
  };

  const handleDeactivate = (id) => {
    setDeactivated({ ...deactivated, [id]: !deactivated[id] });
  };

  const calculatePrice = (variationId) => {
    const supplierVariation = supplierVariations.find(
      (sv) => sv.variation_id === variationId
    );
  
    if (!supplierVariation) return 0;
  
    const productionCost = parseFloat(supplierVariation.price) || 0;
    const shippingCost = parseFloat(supplierVariation.reference_shipping_price) || 0;
    const baseCost = productionCost + shippingCost;
    const withProfit = baseCost * (1 + (profitMargin / 100));
    const withCommission = withProfit / (1 - (platformCommission / 100));
    const finalPrice = withCommission / (1 - (storeDiscount / 100));
    return Math.round(finalPrice * 100) / 100;
  };

  const applyAutoPricing = () => {
    const newPrices = {};
    variations.forEach((variation) => {
      newPrices[variation.id] = calculatePrice(variation.id);
    });
    setPrices(newPrices);
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('No token found');
      return;
    }
    
    const hasInvalidPrices = variations.some(variation => {
      const price = prices[variation.id];
      return price === '' || price === undefined || isNaN(price) || price < 0;
    });

    if (hasInvalidPrices) {
      alert('Please enter valid prices for all variations. Prices must be 0 or greater.');
      return;
    }

    setIsSaving(true);

    const dataToSave = variations.map((variation) => ({
      variation_id: variation.id,
      friendly_dimension_name: friendlyDimensionNames[variation.id] || '',
      friendly_frame_name: friendlyFrameNames[variation.id] || '',
      price: prices[variation.id] || 0,
      quantity: quantities[variation.id] || (isRandomActive ? generateRandomQuantity() : 0),
      is_enabled: !deactivated[variation.id],
      unit: selectedUnit,
    }));

    try {
      await axios.post(
        'https://api.canvashelper.com/api/save-customer-variations',
        { variations: dataToSave },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Varyasyonlar başarıyla kaydedildi!');
    } catch (error) {
      console.error('Error saving variations:', error);
      alert('Varyasyonlar kaydedilirken bir hata oluştu.');
    } finally {
      setIsSaving(false);
    }
  };
  
  const handlePriceWheel = (e) => {
    e.target.blur();
  };
  
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-semibold mb-6">Customer Variations</h2>

      <div className="flex flex-col lg:flex-row lg:items-center gap-4 bg-white p-4 rounded-lg shadow-sm">
        <div className="flex flex-col sm:flex-row gap-4 flex-grow">
          <div className="flex items-center gap-4">
            <div className="w-24">
              <label className="text-sm text-gray-600">Min</label>
              <input
                type="number"
                value={randomQuantity.min}
                onChange={(e) => setRandomQuantity({ ...randomQuantity, min: parseInt(e.target.value, 10) || 1 })}
                className="w-full px-3 py-1.5 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="w-24">
              <label className="text-sm text-gray-600">Max</label>
              <input
                type="number"
                value={randomQuantity.max}
                onChange={(e) => setRandomQuantity({ ...randomQuantity, max: parseInt(e.target.value, 10) || 1 })}
                className="w-full px-3 py-1.5 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex flex-col justify-end">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isRandomActive}
                  onChange={handleRandomCheckbox}
                  className="mr-2"
                />
                <span className="text-sm text-gray-700">Random Qty</span>
              </label>
            </div>
          </div>

          <div className="w-40">
            <label className="text-sm text-gray-600">Unit</label>
            <select
              value={selectedUnit}
              onChange={(e) => setSelectedUnit(e.target.value)}
              className="w-full px-3 py-1.5 border rounded focus:ring-2 focus:ring-blue-500"
            >
              {availableUnits.map((unit) => (
                <option key={unit.value} value={unit.value}>
                  {unit.label}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={useOriginalNames}
            className="bg-gray-500 text-white px-4 py-1.5 rounded hover:bg-gray-600 transition-colors self-end"
          >
            Use Original Names
          </button>
        </div>
      </div>

      <div className="mb-6 p-4 bg-gray-50 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Otomatik Fiyatlandırma Aracı</h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-700 mb-2 text-sm">Kar Marjı (%)</label>
            <input
              type="number"
              min="0"
              value={profitMargin}
              onChange={(e) => setProfitMargin(Math.max(0, parseInt(e.target.value) || 0))}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          <div className="flex flex-col">
            <label className="text-gray-700 mb-2 text-sm">Platform Komisyonu (%)</label>
            <input
              type="number"
              min="0"
              max="100"
              value={platformCommission}
              onChange={(e) => setPlatformCommission(Math.max(0, Math.min(100, parseInt(e.target.value) || 0)))}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          
          <div className="flex flex-col">
            <label className="text-gray-700 mb-2 text-sm">Mağaza İndirimi (%)</label>
            <input
              type="number"
              min="0"
              max="100"
              value={storeDiscount}
              onChange={(e) => setStoreDiscount(Math.max(0, Math.min(100, parseInt(e.target.value) || 0)))}
              className="w-full border rounded-lg px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <button
          onClick={applyAutoPricing}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition duration-200"
        >
          Otomatik Fiyatlandırmayı Uygula
        </button>
      </div>
              
      <div className="w-full p-4 bg-white rounded-lg shadow">
        <div className="relative overflow-hidden border rounded-lg">
          <div className="overflow-x-auto overflow-y-auto max-h-80 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="min-w-full inline-block align-middle">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="sticky top-0 z-10">
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2 border">Dimension</th>
                    <th className="px-4 py-2 border">Frame</th>
                    <th className="px-4 py-2 border">Friendly Dimension Name</th>
                    <th className="px-4 py-2 border">Friendly Frame Name</th>
                    <th className="px-4 py-2 border">Production Cost</th>
                    <th className="px-4 py-2 border">Shipping Cost</th>
                    <th className="px-4 py-2 border">Price</th>
                    <th className="px-4 py-2 border">Quantity</th>
                    <th className="px-4 py-2 border">Deactivate</th>
                  </tr>
                </thead>
                <tbody>
                  {variations.map((variation) => {
                    const supplierVariation = supplierVariations.find(
                      (sv) => sv.variation_id === variation.id
                    );

                    return (
                      <tr key={variation.id} className="hover:bg-gray-50">
                        <td className="px-4 py-2 border">{variation.dimension_name}</td>
                        <td className="px-4 py-2 border">{variation.frame_name}</td>
                        <td className="px-4 py-2 border">
                          <div className="flex items-center space-x-2">
                            <input
                              type="text"
                              placeholder="Friendly Dimension Name"
                              value={friendlyDimensionNames[variation.id] || ''}
                              onChange={(e) =>
                                setFriendlyDimensionNames({
                                  ...friendlyDimensionNames,
                                  [variation.id]: e.target.value,
                                })
                              }
                              className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <span className="text-gray-500">({selectedUnit})</span>
                          </div>
                        </td>
                        <td className="px-4 py-2 border">
                          <input
                            type="text"
                            placeholder="Friendly Frame Name"
                            value={friendlyFrameNames[variation.id] || ''}
                            onChange={(e) =>
                              setFriendlyFrameNames({
                                ...friendlyFrameNames,
                                [variation.id]: e.target.value,
                              })
                            }
                            className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                        </td>
                        <td className="px-4 py-2 border text-right">
                          {formatNumber(supplierVariation?.price)}
                        </td>
                        <td className="px-4 py-2 border text-right">
                          {formatNumber(supplierVariation?.reference_shipping_price)}
                        </td>
                        <td className="px-4 py-2 border">
                          <input
                            type="number"
                            min="0"
                            step="0.01"
                            placeholder="Price"
                            value={prices[variation.id] || ''}
                            onChange={(e) => handlePriceChange(variation.id, e.target.value)}
                            onWheel={handlePriceWheel}
                            className={`border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500
                            ${prices[variation.id] < 0 ? 'border-red-500' : 'border-gray-300'}`}
                          />
                          {prices[variation.id] < 0 && (
                            <div className="absolute text-xs text-red-500 mt-1">
                              Price must be 0 or greater
                            </div>
                          )}
                        </td>
                        <td className="px-4 py-2 border">
                          <input
                            type="number"
                            placeholder="Quantity"
                            value={quantities[variation.id] || (isRandomActive ? '' : '')}
                            onChange={(e) => handleQuantityChange(variation.id, e.target.value)}
                            className="border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            disabled={isRandomActive}
                          />
                        </td>
                        <td className="px-4 py-2 border">
                          <button
                            onClick={() => handleDeactivate(variation.id)}
                            className={`px-4 py-2 rounded-lg text-white ${
                              deactivated[variation.id]
                                ? 'bg-red-500 hover:bg-red-600'
                                : 'bg-green-500 hover:bg-green-600'
                            }`}
                          >
                            {deactivated[variation.id] ? 'Activate' : 'Deactivate'}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center gap-4">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`flex items-center gap-2 px-6 py-3 rounded-lg text-white ${
            isSaving 
              ? 'bg-blue-400 cursor-not-allowed' 
              : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {isSaving && (
            <Loader2 className="w-5 h-5 animate-spin" />
          )}
          {isSaving ? 'Saving...' : 'Save Variations'}
        </button>
      </div>
    </div>
  );
}

export default CustomerVariations;